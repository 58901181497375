export const company = {
  statusId: 1,
  companyId: 20126,
  codigoUsuarioMaestro: 10075,
};

export const companyForm = {
  id: 20126,
};

export const paginationTopLimit = {
  limit: 9,
  topLimit: 999999999,
  limitPage: 1,
};
